import React from 'react';
import VideoTwo from '../elements/video/VideoTwo';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceThree from '../elements/service/ServiceThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import BrandThree from '../elements/brand/BrandThree';
import TeamThree from '../elements/team/TeamThree';
import PricingTwo from '../elements/pricing/PricingTwo';

import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import { BannerActivation } from "../utils/script";
import ScrollAnimation from "react-animate-on-scroll";

import Slider from "react-slick";

var BlogListData = BlogClassicData.slice(0, 3);


const BannerData1 = [
    {
        image: "/images/otm2025/OTM-banner.jpg",
        title: "Connecting Travel and Tourism Professionals all around the world",
        description: "Connecting Travel and Tourism Professionals all around the world"
    }
    
]

const BannerData = [
    // {
    //     image: "./images/otm2025/1.png"
    // },
    {
        image: "/images/otm2025/2.jpg"
    },
    // {
    //     image: "/images/otm2025/2.png"
    // },
    {
        image: "/images/otm2025/3.jpg"
    },
    // {
    //     image: "/images/otm2025/3.png"
    // },
    {
        image: "/images/otm2025/4.jpg"
    },
    {
        image: "/images/otm2025/5.jpg"
    },
    {
        image: "/images/otm2025/AA000163_edited.jpg"
    },
    {
        image: "/images/otm2025/DJI_0080_edited.jpg"
    },
    {
        image: "/images/otm2025/IMR_0287_edited.jpg"
    },
    {
        image: "/images/otm2025/_DIS0268_edited.jpg"
    },
    {
        image: "/images/otm2025/_DIS0691_edited.jpg"
    }
    
]



const Otm2025 = () => {

    var settings = {
        dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
      };

    return (
        <>
            <SEO title="Event Conference" />

            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                {/* <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-750">
                    <div className="container">
                        <div className="align-items-center">
                           
                            <div className="shape-image">
                                <img src="./images/otm2025/OTM-banner.jpg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Slider Area  */}

                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData1.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className={"height-750 bg_image_new "} style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                <div className="row">
                                <div className="col-lg-12">
                                <div className="inner text-center">
                                        {/* <div className="order-2 order-lg-1 col-lg-7"> */}
                                            {/* <div className="inner text-start"> */}
                                            {/* <video autoplay>
                                            <source src={`${process.env.PUBLIC_URL}/images/bg/Video-919.mp4`} type="video/mp4">
                                            </source>
                                            </video> */}
                                                {/* <h5 className="title" dangerouslySetInnerHTML={{__html: data.title}} style={{fontSize:"40px"}}></h5>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p> */}
                                        {/* <div className="read-morebtn"> */}
                                          
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap-events ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "OTM 2025"
                                    description = ""
                                />
                            </div>
                        </div>
                      
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                <div className="container" style={{paddingLeft:"10%", paddingRight:"10%"}}>
                <p style={{textAlign:"justify"}}>Welcome to <b>OTM 2025</b>, Asia’s leading travel trade show and the ultimate destination for connecting with the global travel and tourism industry. Recognized as the “Leading Travel Trade Show in India and Asia” by NielsenIQ, OTM has established itself as the premier platform for professionals seeking to explore innovative opportunities and expand their business horizons. This prestigious certification reflects its unparalleled influence and ability to outperform other major events in the region.</p>
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Asia’s Premier Platform"
                        title = ""
                        description = ""
                    />
                </div>
                
                <p style={{textAlign:"justify"}}>
                OTM 2025 serves as a dynamic hub for travel and tourism professionals from around the globe. As Asia’s most influential trade event, it brings together a diverse array of exhibitors, visitors, and decision-makers. The event offers an ideal platform to foster connections, explore new markets, and showcase the latest travel innovations. With its strong reputation for excellence, OTM is the go-to event for professionals who aim to stay ahead in the competitive travel industry.</p>
                
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Meet the Industry’s Best"
                        title = ""
                        description = ""
                    />
                    <p style={{textAlign:"justify"}}>At OTM 2025, attendees will have the opportunity to engage with over 40,000 visitors, including top-tier travel professionals and decision-makers. Representatives from more than 60 countries will be present, offering a chance to discover unique destinations, cultures, and travel opportunities. The event will feature over 1,600 exhibitors showcasing innovative products and services designed to transform the travel experience. This rich diversity ensures that every attendee finds valuable insights and connections to drive their business forward.</p>
                </div>

                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Drive Your Business Forward"
                        title = ""
                        description = ""
                    />
                    <p style={{textAlign:"justify"}}>
OTM 2025 is not just a trade show—it is a platform for growth and innovation. It offers unmatched opportunities for attendees to expand their networks, form strategic partnerships, and unlock new avenues in the travel and tourism sector. By participating in OTM, businesses can amplify their global presence, enhance their brand reputation, and gain a competitive edge in the market.
</p>
                </div>

                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Benefits for Skålleagues"
                        title = ""
                        description = ""
                    />
                    <p style={{textAlign:"justify"}}>
                    For members of Skål International, OTM 2025 presents an exclusive opportunity to elevate their professional journey. As a Skålleague, you can leverage the event’s extensive reach to grow your business and explore new markets. The event provides a platform to build meaningful relationships with industry experts, innovators, and peers. Additionally, OTM offers an unparalleled stage for showcasing your brand’s expertise and establishing a strong market presence globally.</p>
                </div>

                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Join Us at OTM 2025"
                        title = ""
                        description = ""
                    />
                    <p style={{textAlign:"justify"}}>

                    Be part of the travel event that redefines possibilities. OTM 2025 is more than just an exhibition; it is where the future of travel and tourism takes shape. By attending, you will gain access to transformative experiences, groundbreaking innovations, and a global network of professionals dedicated to excellence. Don’t miss the opportunity to connect, collaborate, and create success at OTM 2025. </p>                </div>

                </div>


                <div className="text-center">
                <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                    <a className="btn-default btn-large round" target="_blank" href="https://otm.co.in/attending/"><span>Register Now</span></a>
                </ScrollAnimation>
                </div>


                <Separator />
                <div className="slider-container mb--30 mt--40" >
                <Slider {...settings}>
                {BannerData.map((data, index) => (
                        <div key={index}>
                            <div className="container">
                    <div className="row row--30 align-items-center">
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-10">
                        <div className="thumbnail">
                            <img className="w-100" src={`${data.image}`} alt="gallery" />
                        </div>
                    </div>
                    <div className="col-lg-1">
                          </div>
                    </div>
                    </div>
                        </div>
                    ))}
                </Slider>
                </div>


                <FooterOne />
                <Copyright />
            </main>

        </>
    )
}

export default Otm2025;
